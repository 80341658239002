"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toLocaleTimeString = exports.toLocaleDateString = exports.toLocaleString = void 0;
// eslint-disable-next-line import/no-cycle
var core_1 = require("./core");
var ToDateTimeOptions_1 = require("./abstract/ToDateTimeOptions");
/**
 * Number.prototype.toLocaleString ponyfill
 * https://tc39.es/ecma402/#sup-number.prototype.tolocalestring
 */
function toLocaleString(x, locales, options) {
    var dtf = new core_1.DateTimeFormat(locales, options);
    return dtf.format(x);
}
exports.toLocaleString = toLocaleString;
function toLocaleDateString(x, locales, options) {
    var dtf = new core_1.DateTimeFormat(locales, (0, ToDateTimeOptions_1.ToDateTimeOptions)(options, 'date', 'date'));
    return dtf.format(x);
}
exports.toLocaleDateString = toLocaleDateString;
function toLocaleTimeString(x, locales, options) {
    var dtf = new core_1.DateTimeFormat(locales, (0, ToDateTimeOptions_1.ToDateTimeOptions)(options, 'time', 'time'));
    return dtf.format(x);
}
exports.toLocaleTimeString = toLocaleTimeString;
